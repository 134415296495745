import React from "react";

const PageHeader = (props) => {
  return (
    <section className="inner-banner">
      <span className="banner-two__shape-1"></span>
      <span className="banner-two__shape-2"></span>
      <span className="banner-two__shape-3"></span>
      <span className="banner-two__shape-4"></span>
      <div className="container">
        <p className="inner-banner__title">{props.title}</p>
      </div>
    </section>
  );
};
export default PageHeader;
