import React from "react";
import Helmet from "react-helmet";
import "../assets/css/animate.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/font-awesome.min.css";
import "../assets/scss/style.scss";
import "../assets/scss/responsive.scss";

const Layout = (props) => {
  
  return (
    <div>
      <Helmet htmlAttributes={{ lang: props.language || 'en' }}>
        <title>{props.pageTitle}</title>
        <meta charSet="UTF-8" />
        <link rel="icon" href="/images/favicon.ico"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        { 
          props.description && 
          <meta name="description" content={props.description} />
        }
        {
          props.publishDate && 
          <meta itemprop="datePublished" content={props.publishDate} />
        }
        <link
          href="https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      
      <div className="page-wrapper">{props.children}</div>
    </div>
  );
};
export default Layout;
