import React, { Component } from "react";
import "../assets/css/footer.css";
import Texts from "../../../utils/lang-utils";
import logoLight from "../assets/images/eatella-logo.png";
import urls from "../assets/urls";
import './Footer.scss';

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollBtn: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 70) {
      this.setState({
        scrollBtn: true,
      });
    } else if (window.scrollY < 70) {
      this.setState({
        scrollBtn: false,
      });
    }
  }

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <footer className="site-footer">
          <div className="site-footer__upper">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="footer-widget footer-widget__about">
                    <a href="/">
                    <img
                      src={logoLight}
                      width="119"
                      alt=""
                      className="footer-widget__logo"
                    /></a>
                    
                  </div>

                    <p className="footer-widget__contact">
                      {Texts.Footer.Chamber}: <br />
                      PolDev, 58289771
                      <br />
                      Omval 417, 1096HR
                      <br />
                      Amsterdam, Netherlands
                    </p>
                </div>
                <div className="col-lg-6 d-flex justify-content-between footer-widget__links-wrap">

                  <div className="footer-widget">
                    <h3 className="footer-widget__title">{Texts.Footer.EatellaForFoodies}</h3>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <a href={urls.app}>
                          {Texts.Footer.EatellaApp}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">{Texts.Footer.EatellaForRestaurants}</h3>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <a href={urls.qrMenu}>{Texts.Footer.QRMenu}</a>
                      </li>
                      <li>
                        <a href={urls.qrOrdering}>{Texts.Footer.QROrdering}</a>
                      </li>
                      <li>
                        <a href={urls.apps}>{Texts.Footer.Apps}</a>
                      </li>
                      <li>
                        <a href={urls.pricing}>{Texts.Footer.Pricing}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-2">
                  <div className="footer-widget">
                  <h3 className="footer-widget__title">{Texts.Footer.About}</h3>
                    <div>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <a href={urls.contact}>
                          {Texts.Footer.Contact}
                        </a>
                      </li>
                      <li>
                        <a href="/privacypolicy.html">{Texts.Footer.Privacy}</a>
                      </li>
                    </ul>
                    </div>
                    <div className="site-footer__social">
                      <a
                        href="https://www.facebook.com/eatellia"
                        target="_blank"
                        aria-label="Like us on Facebook"
                        aria-hidden="true"
                      >
                        <i className="fa fa-facebook-square"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/valeria_eatella/"
                        target="_blank"
                        aria-label="Follow us on Instagram"
                        aria-hidden="true"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="inner-container text-center">
                <p className="site-footer__copy">{new Date().getFullYear()}&copy; PolDev</p>
              </div>
            </div>
          </div>
        </footer>

        {/* <div
          onClick={this.scrollTop}
          onKeyDown={this.scrollTop}
          role="button"
          tabIndex="0"
          className="scroll-to-target scroll-to-top"
          style={{ display: this.state.scrollBtn ? "block" : "none" }}
        >
          <i className="fa fa-angle-up"></i>
        </div> */}
      </div>
    );
  }
}
