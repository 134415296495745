import React from "react";
import Layout from "../../areas/main/components/Layout";
import NavBlog from "../../areas/main/components/NavBlog";
import PageHeader from "../../areas/main/components/PageHeader";
import NewsDetails from "../../areas/main/components/NewsDetails";
import Footer from "../../areas/main/components/Footer";

const BlogPostPage = () => (
  <Layout publishDate="2021-05-22"
  pageTitle="Restaurant barcode or QR code menu is so '90s. Meet NFC menu. | EATELLA Blog"
  description="Contactless digital menu typically opens when you scan a QR code. But is it really the best way to start your contactless dining experience? Restaurant barcode or QR code menu could be replaced by NFC soon.">
    <NavBlog />
    <PageHeader title="EATELLA > BLOG" />
    <NewsDetails />
    <Footer />
  </Layout>
);

export default BlogPostPage;
