import React from "react";
import blogDetails from "../assets/images/resources/restaurant-barcode-qr-code-menu.png";
const NewsDetails = () => {
  return (
    <section className="blog-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="blog-one__single">
              <div className="blog-one__content">
              <h1 className="blog-one__title">
                  Restaurant barcode or QR code menu is so '90s. <br/>Meet NFC menu.
                </h1>
                <ul className="list-unstyled blog-one__meta">
                  <li>
                    22 May, 2021
                  </li>
                  <li>
                    4 min read
                  </li>
                </ul>
              <div className="blog-one__image">
                <img src={blogDetails} alt="restaurant barcode and qr code menu" />
              </div>

                

                <p >
                Using QR codes in restaurants is quite popular these days. Not only because of pandemic but also because it makes things easier. Easier  for clients to use and easier for restaurants to setup and maintain. And! It is also eco-friendlier! Since it's all digital there is no paper printing involved for every little change of  a menu. Although currently traditional menus are still preferred by most customers, things are changing rapidly. People are getting used to scanning QR codes and it's becoming an usual activity when entering almost any public place. 
                </p>
                <h2>Digital QR menu is here to stay</h2>
                <p >
                But is the QR code actually the best technology for this? It is contactless, it's fast enough and it does what it needs to do but it does come with some quirks. Did you know that many people out there are still calling it "barcode"?  This probably because "barcode" is easier to pronounce and it's been used forever while QR's popularity has been on and off lately. Anyway, whatever you're used to call it you probably won't need to remember it soon anyway.  
                <br/>
                </p>
                <h3>Say hello to NFC menus</h3>
                <p >
                
Rather than using phone's camera to scan a QR you use NFC chip that is now available in most of the modern smartphones. You just hold your phone near an NFC "tag" which is a circle of 3,5cm in diameter with a tiny antenna inside and it opens a web address in the same way as qr code does.
</p><p >The advantages to this way over QR codes:
<ul>
    <li>It's usually faster. Scanning a QR code can take longer depending on lighting conditions or may even fail, especially on lower end devices. Scanning NFC tag works instantly because the website address is transmitted as digital signal via antenna.</li>
    <li>Many Android phones still don't have native support for scanning QR or barcode. Which means you have to install an app. And it's actually not that easy to find a good one. Most of them are blown up by ads and just crappy and ugly. In fact, many Android users still don't even know how to scan a QR code. With NFC you don't need any apps, it's supported natively by Android. You currently do need an app, however, on iPhone which is a shame but this will probably change in coming iOS versions.</li>
    <li>Finally, NFC information can be changed any time without replacing the tag. With QR codes you'd have print a new one every time in case you want to change the web address.</li>
</ul>

                </p>

                <p>Anyway, long story short, currently it's best to offer both QR and NFC options to open your digital menu and let your customers choose what works for them best. In future QR menus could be fading away and maybe completely replaced by NFC menus.</p>
                
                <p>
                  Click here to generate <a href="/qr-menu/">contactless digital menu</a> for your restaurant for free.
                </p>
              </div>
            </div>
            <div className="share-block">
              <div className="social-block">
                <a aria-label="social icon" aria-hidden="true" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Featella.app%2Fblog%2Frestaurant-barcode-qr-code-menu%2F" target="_blank" rel="noreferrer">
                  <i className="fa fa-facebook-square"></i>
                </a>
                <a aria-label="social icon" aria-hidden="true" href="http://twitter.com/share?text=Restaurant%20QR%20code%20menus%20are%20so%2090%27s.%20Meet%20NFC%20menu.&url=https%3A%2F%2Featella.app%2Fblog%2Frestaurant-barcode-qr-code-menu%2F&hashtags=qrmenu,eatellia" target="_blank" rel="noreferrer">
                  <i className="fa fa-twitter"></i>
                </a>
                <a aria-label="social icon" aria-hidden="true" href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Featella.app%2Fblog%2Frestaurant-barcode-qr-code-menu%2F" rel="noreferrer">
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
            </div>
            </div>
          </div>
      </div>
    </section>
  );
};
export default NewsDetails;
